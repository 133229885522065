import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
  save?: number;
  containerClassName?: string;
  perMonth?: boolean;
}

export const SalesLabel: FC<Props> = ({ save, containerClassName, perMonth }) => {
  const { t } = useTranslation();

  if (!save) {
    return null;
  }

  return (
    <div className={twMerge("relative w-fit", containerClassName)}>
      <div className="bg-green w-2 h-2 rotate-45 absolute -top-1 left-1/2 -translate-x-1/2" />
      <div className="bg-green px-4 h-7 rounded-3xl flex justify-center items-center text-white text-xxs">
        {t("pricing.save", { save })} {!!perMonth && t('pricing.perMonth')}
      </div>
    </div>
  );
};
