import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";

import "./config/i18n";
import { router } from "./router/main.router";
import { queryClient } from "./config/query-client";
// import { MetaPixel } from "./connections/meta-pixel.connection";
import { GoogleTagManager } from "./connections/google-tag-manager.connection";
import { FreshChat } from "./connections/fresh-chat.connection";
// import { HubSpot } from "./connections/hubspot.connection";

import "utils/clarity";

import "./App.css";

function App() {
    return (
        <HelmetProvider>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                {/*<MetaPixel />*/}
                <GoogleTagManager />
                {/* <HubSpot /> */}
                <FreshChat />
            </QueryClientProvider>
        </HelmetProvider>
    );
}

export default App;
