import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { Text } from "./typography.component";
import { Button } from "./button.component";
import { PackageItemProps } from "types";
import { ButtonType } from "enums";
import { SalesLabel } from "./sales-label.component";

interface Props extends PackageItemProps {
  onButtonClick: () => void;
}

export const PricingPackageItem: FC<Props> = ({
  name,
  description,
  save,
  price,
  days,
  onButtonClick,
  perMonth
}) => {
  const { t } = useTranslation();

  const isAnnually = days === 365;

  return (
    <li
      key={days}
      aria-label={t("arialLabels.subscriptionPackage", { name, days })}
      className={twMerge(
        "px-4 py-8 rounded-lg bg-secondary-dark bg-opacity-50 hover:bg-opacity-100 h-full border",
        days === 180 ? "border-primary" : "border-secondary-dark"
      )}
    >
      <Text className="text-xl font-semibold mb-3 leading-[126%]">
        {t(name)}
      </Text>
      <Text className="text-3xl font-semibold leading-[126%] mb-2">
        ${price}
      </Text>
      {isAnnually ? (
        <Text className="text-xs text-opacity-60 mb-5">
          {t("pricing.paidAnnually")}
        </Text>
      ) : (
        ""
      )}

      <div className="mb-3 pb-3 border-b border-dashed border-white border-opacity-10 relative">
        <Button
          buttonType={days === 180 ? ButtonType.FILLED : ButtonType.OUTLINED}
          onClick={onButtonClick}
          className={twMerge(!isAnnually && 'mt-9', !save && 'mb-7')}
        >
          {t("pricing.subscribe")}
        </Button>
        <SalesLabel
          save={save}
          containerClassName="left-1/2 -translate-x-1/2 bottom-1"
          perMonth={perMonth}
        />
      </div>

      <Text className="font-medium mb-2">{t("pricing.days", { days })}</Text>
      <Text className="text-xs text-opacity-80 leading-[150%]">
        {t(description)}
      </Text>
    </li>
  );
};
