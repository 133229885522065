import {
  MY_ACCOUNT_PATH,
  CREATE_ACCOUNT_PATH,
  FREE_TRIAL_PATH,
} from "router/constants";
import { SectionId, SupportedPlatform } from "enums";
import {
  ServiceItemProps,
  NavigationLinkProps,
  PackageItemProps,
  FeedbackItemProps,
  ManageAccountButtonsProps,
  PaymentMethodsProps,
  GuidesData,
} from "types";

import movies from "images/movies.png";
import breakingNewsImage from "images/breaking-news.png";
import apolloOffersImage from "images/apollo-offers.png";
import ourServiceImage from "images/our-service.png";
import leeDaniel from "images/lee-daniel.png";
import kathrynShelton from "images/kathryn-shelton.png";
import bettyMoore from "images/betty-moore.png";
import cashApp from "images/cash-app.png";
import bitcoin from "images/bitcoin.png";
import shakepay from "images/shakepay.png";

import { AppleIcon } from "components/icons/apple.icon";
import { AndroidIcon } from "components/icons/android.icon";
import { FireStickIcon } from "components/icons/fire-stick.icon";
import { AmazoneIcon } from "components/icons/amazon.icon";
import { SakepayGuidePage } from "pages/guides/shakepay-guide.page";
import { CashappGuidePage } from "pages/guides/cashapp-guide.page";
import { VenmoCryptoGuidePage } from "pages/guides/venmo-crypto-guide.page";
import { BitbuyCryptoGuidePage } from "pages/guides/bitbuy-crypto-guide.page";
import { CoinsmartGuidePage } from "pages/guides/coinsmart-guide.page";
import { MoonPayGuidePage } from "pages/guides/moonpay-guide.page";

export const navigationLinkContent: NavigationLinkProps[] = [
  {
    name: "navigation.homePage",
    id: SectionId.HOME_PAGE,
  },
  {
    name: "navigation.services",
    id: SectionId.SERVICES,
  },
  {
    name: "navigation.pricingPackages",
    id: SectionId.PRICING,
  }
];

export const manageAccountButtons: ManageAccountButtonsProps[] = [
  {
    name: "navigation.login",
    path: MY_ACCOUNT_PATH,
  },
  {
    name: "navigation.createAccount",
    path: CREATE_ACCOUNT_PATH,
  },
  {
    name: "navigation.freeTrial",
    path: FREE_TRIAL_PATH,
  },
];

export const servicesContent: ServiceItemProps[] = [
  {
    title: "services.onTheGo",
    text: "services.onTheGoText",
    image: movies,
  },
  {
    title: "services.watchBreakingNews",
    text: "services.watchBreakingNewsText",
    image: breakingNewsImage,
  },
  {
    title: "services.apolloOffers",
    text: "services.apolloOffersText",
    image: apolloOffersImage,
  },
  {
    title: "services.ourService",
    text: "services.ourServiceText",
    image: ourServiceImage,
  },
];

export const packagesBenefits = [
  "pricing.benefits.devicesPerUser",
  "pricing.benefits.hdChannels",
  "pricing.benefits.countries",
  "pricing.benefits.categories",
  "pricing.benefits.vodLibrary",
];

export const packagesContent: PackageItemProps[] = [
  {
    name: "pricing.packages.starter",
    price: 24.99,
    days: 30,
    description: "pricing.packages.starterText",
  },
  {
    name: "pricing.packages.growth",
    price: 51.99,
    days: 90,
    description: "pricing.packages.growthText",
    save: 29,
  },
  {
    name: "pricing.packages.elite",
    price: 89.99,
    days: 180,
    description: "pricing.packages.eliteText",
    save: 39,
  },
  {
    name: "pricing.packages.professional",
    price: 159.99,
    days: 365,
    description: "pricing.packages.professionalText",
    save: 49,
    perMonth: true,
  },
];

export const feedbacksContent: FeedbackItemProps[] = [
  {
    image: leeDaniel,
    name: "Lee Daniel",
    city: "Louisville, KY",
    feedback: "feedbacks.leeDanielText",
  },
  {
    image: kathrynShelton,
    name: "Kathryn Shelton",
    city: "North Hempstead, NY",
    feedback: "feedbacks.kathrynSheltonText",
  },
  {
    image: bettyMoore,
    name: "Betty Moore",
    city: "Philadelphia, PA",
    feedback: "feedbacks.bettyMooreText",
  },
];

export const createAccountIframePath = "/register";

export const freeTrialIframePath = `${createAccountIframePath}?trial=true`;

export const applicationsInfo = {
  [SupportedPlatform.IOS]: {
    name: "iOS",
    Icon: AppleIcon,
  },
  [SupportedPlatform.ANDROID]: {
    name: "Android",
    Icon: AndroidIcon,
  },
  [SupportedPlatform.FIRESTICK]: {
    name: "FIREstick",
    Icon: FireStickIcon,
  },
  [SupportedPlatform.AMAZONE]: {
    name: "Amazone",
    Icon: AmazoneIcon,
  },
};

export const paymentMethodsContent: PaymentMethodsProps[] = [
  {
    name: "Cash App",
    image: cashApp,
  },
  {
    name: "Bitcoin",
    image: bitcoin,
  },
  {
    name: "Shakepay",
    image: shakepay,
  },
];

export const guidesData: GuidesData[] = [
  {
    id: "1",
    name: "cashappGuide.title",
    content: <CashappGuidePage />,
  },
  {
    id: "2",
    name: "moonpayGuide.title",
    content: <MoonPayGuidePage />,
  },
  {
    id: "3",
    name: "venmoCryptoGuide.title",
    content: <VenmoCryptoGuidePage />,
  },
  {
    id: "4",
    name: "bitbuyCryptoGuide.title",
    content: <BitbuyCryptoGuidePage />,
  },
  {
    id: "5",
    name: "coinsmartGuide.title",
    content: <CoinsmartGuidePage />,
  },
  {
    id: "6",
    name: "shakepayGuide.title",
    content: <SakepayGuidePage />,
  },
];
